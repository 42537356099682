import { Link } from "gatsby"
import React from "react"
import { StandardButton } from "../atoms/Button"
import { Order } from "../formPage/FormPage"

type LineItem = {
  name: string
  label: string
  price: number
}

const Promotion: React.FC<{
  content: React.ReactChild
  priceNum?: number
  originalPrice?: number
  minimal?: boolean
  heading?: string
  title?: string
  sticker?: boolean
  lineItems?: LineItem[]
}> = ({
  content,
  priceNum = 26_500,
  originalPrice = 29_350,
  minimal = false,
  sticker = true,
  heading = "En veckas intensivkurs i Göteborg",
  title = "Intensivkurs MC Göteborg",
  lineItems = [
    {
      name: "Intensivkurs MC Göteborg",
      label: "1 veckas Intensivkurs (MC)",
      price: priceNum,
    },
  ],
}) => {
  return (
    <section className="max-w-lg px-4 mx-auto pt-8 flex flex-col justify-center items-center">
      {sticker ? (
        <h6 className="text-4xl font-black p-4 bg-red-600 text-white uppercase -rotate-2 transform">
          {title}
        </h6>
      ) : null}
      <div className="flex">
        <div className="flex flex-col gap-2 flex-1 items-start">
          {/* <h2>{heading}</h2> */}
          {!minimal ? (
            <div className="flex flex-col list-disc justify-end text-md">
              {content}
            </div>
          ) : null}
          <div className="flex items-end gap-4">
            <span className="text-5xl font-black text-red-500">
              {priceNum}:-{" "}
            </span>
            <span className="text-2xl font-bold">
              (ord. {originalPrice}:-){" "}
            </span>
          </div>
          <span className="font-bold text-2xl italic">
            <strong className="font-black">
              {originalPrice - priceNum}:-{" "}
            </strong>
            rabatt, passa på!
          </span>
          <Link
            to={"/formular"}
            state={{
              orderState: {
                items: lineItems,
              },
              orderType: Order.COURSE,
            }}
          >
            <div css={StandardButton}>Boka nu direkt!</div>
          </Link>
        </div>
        {/* <img className={"max-w-sm"} src={require("./../../images/car.png")} />
         */}
      </div>
    </section>
  )
}

export default Promotion
